import {
  mergeAttributes,
  Node,
} from '@tiptap/core'

export interface HighLightSectionOptions {
  HTMLAttributes: Record<string, any>,
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    highlightSection: {
      toggleHighlightSection: () => ReturnType
    }
  }
}

export const HighLightSection = Node.create<HighLightSectionOptions>({
  name: 'highlight-section',

  inline: false,

  draggable: true,

  group: 'block',

  content: 'block+',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(
        { 'data-type': this.name },
        this.options.HTMLAttributes,
        HTMLAttributes,
        {
          'class': 'highlight-section'
        }
      ),
      0
    ]
  },

  addCommands() {
    return {
      toggleHighlightSection: () => ({ commands }) => {
        return commands.toggleWrap(this.name)
      }
    }
  },

  addKeyboardShortcuts() {
    return {
      'Mod-k': () => this.editor.commands.toggleHighlightSection(),
    }
  },
})
