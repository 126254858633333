import {
  mergeAttributes,
  Node,
} from '@tiptap/core'

export interface FigcaptionOptions {
  HTMLAttributes: Record<string, any>,
}

export const Figcaption = Node.create<FigcaptionOptions>({
  name: 'figcaption',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  inline: false,

  group: 'block',

  content: 'inline*',

  isolating: true,

  draggable: false,

  addAttributes() {
    return { }
  },

  parseHTML() {
    return [
      {
        tag: 'figcaption',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'figcaption',
      mergeAttributes(
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
      0
    ]
  },
})
