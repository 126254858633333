import { Document } from "@tiptap/extension-document";
import { Heading, type Level } from "@tiptap/extension-heading";
import { Text} from "@tiptap/extension-text";
import { Bold} from "@tiptap/extension-bold";
import { Italic } from "@tiptap/extension-italic";
import { Strike } from "@tiptap/extension-strike";
import { HardBreak } from "@tiptap/extension-hard-break";
import { Twemoji } from "~/tiptap/twemoji";
import { Paragraph } from "@tiptap/extension-paragraph";
import { CodeBlock } from "@tiptap/extension-code-block";
import { Blockquote } from "@tiptap/extension-blockquote";
import { ListItem } from "@tiptap/extension-list-item";
import { OrderedList } from "@tiptap/extension-ordered-list";
import { BulletList } from "@tiptap/extension-bullet-list";
import { HorizontalRule } from '@tiptap/extension-horizontal-rule'
import { Underline } from "@tiptap/extension-underline";
import { Code } from "@tiptap/extension-code";
import { TextAlign } from "@tiptap/extension-text-align";
import { Subscript } from "@tiptap/extension-subscript";
import { Superscript } from "@tiptap/extension-superscript";
import { Link, type LinkOptions } from "@tiptap/extension-link";
import { UserMention } from "~/tiptap/mention";
import { HighLightSection } from "~/tiptap/highlight-section";
import { Figcaption, Figure } from "~/tiptap/figure";
import { Gallery } from "~/tiptap/gallery";
import { CharonFile } from "~/tiptap/charon-file";
import { Youtube } from "~/tiptap/youtube";

export const titleExtensions = (options?: Partial<{
  customHeadingLevel?: Level
}>) => {
  return markRaw([
    // nodes
    Document.configure({
      content: 'heading'
    }),
    Heading.configure({
      levels: [options?.customHeadingLevel || 1]
    }),
    Text,
    HardBreak,
    Twemoji,

    // marks
    Bold,
    Italic,
    Strike,
  ])
}

export const teaserExtensions = (options?: Partial<{
  link: Partial<LinkOptions>,
}>) => markRaw([
  // nodes
  Document,
  Paragraph,
  Text,
  HardBreak,
  Twemoji,
  UserMention,

  // marks
  Bold,
  Italic,
  Strike,
  Link.configure(options?.link),
])

export const bodyExtensions = (options?: Partial<{
  link: Partial<LinkOptions>,
}>) => markRaw([
  // nodes
  Document,
  Heading.configure({
    levels: [ 2, 3, 4 ]
  }),
  Text,
  Paragraph,
  CodeBlock,
  Blockquote,
  ListItem,
  OrderedList,
  BulletList,
  HorizontalRule,
  Twemoji,
  HighLightSection,
  Figcaption, Figure,
  Gallery,
  CharonFile,
  HardBreak,
  UserMention,
  Youtube.configure({
    nocookie: true,
  }),
  // marks
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  TextAlign,
  Subscript,
  Superscript,
  Link.configure(options?.link),
])
