import {
  mergeAttributes,
  Node,
} from '@tiptap/core'

export interface FigureOptions {
  HTMLAttributes: Record<string, any>,
}

export const Figure = Node.create<FigureOptions>({
  name: 'figure',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  inline: false,

  group: 'block',

  content: 'fig figcaption',

  draggable: true,

  addAttributes() {
    return { }
  },

  parseHTML() {
    return [
      {
        tag: 'figure'
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'figure',
      mergeAttributes(
        this.options.HTMLAttributes,
        HTMLAttributes,
        { class: 'img-container' }
      ),
      0
    ]
  },
})
