import {
  mergeAttributes,
  Node,
} from '@tiptap/core'

export interface GalleryOptions {
  HTMLAttributes: Record<string, any>,
}

export const Gallery = Node.create<GalleryOptions>({
  name: 'gallery',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  inline: false,

  group: 'block fig',

  content: 'fig+',

  draggable: true,

  addAttributes() {
    return { }
  },

  parseHTML() {
    return [
      {
        tag: 'div[gallery]'
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(
        this.options.HTMLAttributes,
        HTMLAttributes,
        { 'class': 'gallery', 'gallery': null }
      ),
      0
    ]
  },
})
