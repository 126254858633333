import {
  mergeAttributes,
  Node,
} from '@tiptap/core'
import { VueNodeViewRenderer } from "@tiptap/vue-3";
import CharonFileWrapper from "./CharonFileWrapper.vue";

export interface ImageOptions {
  HTMLAttributes: Record<string, any>,
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    charonFile: {
      updateCharonFile: (options: { fileId: string }) => ReturnType
    }
  }
}

export const CharonFile = Node.create<ImageOptions>({
  name: 'charon-file',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  inline: false,

  group: 'block fig',

  atom: true,

  draggable: true,

  addAttributes() {
    return {
      fileId: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'charon-file'
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'charon-file',
      mergeAttributes(
        this.options.HTMLAttributes,
        HTMLAttributes
      )
    ]
  },

  addNodeView() {
    return VueNodeViewRenderer(CharonFileWrapper)
  },

  addCommands() {
    return {
      updateCharonFile: options => ({ commands }) => {
        return commands.updateAttributes(this.name, { fileId: options.fileId })
      }
    }
  },
})
