<template>
  <!--suppress TypeScriptValidateTypes -->
  <DynamicResource
    v-if="!pending"
    :resource="{
      mediaType: reference?.original.mediaType ?? 'image/png',
      src: src,
      alt: reference?.description,
      options: props.options,
    }"
  />
</template>

<script setup lang="ts">
import type { FileReference } from "~/utils/charon-types";

const props = defineProps<{
  fileId: string | null,
  options?: any
}>()

const config = useRuntimeConfig()
const charon = useCharon()

const { pending, data: reference } = useAsyncData(
  `file-reference-${props.fileId ?? 'placeholder'}`,
  () => props.fileId ? charon<FileReference>(`/file-references/${props.fileId}`) : Promise.resolve(null),
  {
    lazy: true,
    server: true
  }
)

const src = computed(() => {
  if (reference.value == null) {
    return "images/component/charonFile/news_placeholder.png"
  }
  if (reference?.value?.original.mediaType?.startsWith("image")) {
    return `/charon/${reference.value?.id}`
  }
  return `${config.public.charonUrl}/file-references/file/${reference.value?.id}`
})
</script>
