import { VueRenderer } from '@tiptap/vue-3'
import tippy from 'tippy.js'
import type { Instance as TippyInstance } from 'tippy.js'
import MentionSuggestions from './MentionSuggestions.vue'
// @ts-ignore
import { SuggestionKeyDownProps, SuggestionOptions, SuggestionProps } from "@tiptap/suggestion";
import type { User } from "~/utils/charon-types";

type Suggestion = Omit<SuggestionOptions<User>, 'editor'> //MentionOptions['suggestion']

// noinspection ES6ShorthandObjectProperty
// @ts-ignore
const usersSuggestion: Suggestion = {
  // @ts-ignore
  items: async ({ query }) => {
    const charon = useCharon(true)
    return charon<User[]>(`/users`, {
      params: {
        'name[fuzzyi]': query,
        sort: 'name[asc]',
        size: 5,
      }
    })
  },
  render() {
    let component: VueRenderer
    let popups: TippyInstance[]

    return {
      // @ts-ignore
      onStart: props => {
        component = new VueRenderer(MentionSuggestions, {
          props,
          editor: props.editor,
        })

        popups = tippy('body', {
          getReferenceClientRect: props.clientRect as (() => DOMRect) | null,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })
      },

      // @ts-ignore
      onUpdate(props) {
        component.updateProps(props)
        popups[0].setProps({
          getReferenceClientRect: props.clientRect as (() => DOMRect) | null,
        })
      },

      // @ts-ignore
      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popups[0].hide()
          return true
        }
        return component.ref?.onKeyDown(props.event)
      },

      onExit() {
        popups[0].destroy()
        component.destroy()
      },
    };
  }
}
export { usersSuggestion }
export default usersSuggestion
