<template>
  <node-view-wrapper class="charon-img">
    <CharonFile
      :key="props.node.attrs.fileId"
      :file-id="props.node.attrs.fileId"
      :options="{
        sizes: '100vw tablet:830px laptop:950px',
        magnify: !props.editor?.isEditable,
      }"
    />
  </node-view-wrapper>
</template>

<script setup lang="ts">
import {Editor, NodeViewWrapper} from '@tiptap/vue-3'

// see https://stackoverflow.com/questions/72512237/how-to-import-nodeviewprops-object-from-tiptap-in-vue-3-script-setup
const props = defineProps({
  node: {
    type: Object,
    required: true
  },
  editor: Editor,
  updateAttributes: {
    type: Function,
    required: true,
  }
})
</script>
