<template>
  <div
    class="items"
  >
    <template v-if="items.length">
      <button
        v-for="(item, index) in items"
        :key="item.id"
        class="item"
        :class="{ 'is-selected': index === selectedIndex }"
        @click="selectItem(index)"
      >
        {{ item.name }}
      </button>
    </template>
    <div
      v-else
      class="item"
    >
      No result
    </div>
  </div>
</template>

<script setup lang="ts">
import type {User} from "~/utils/charon-types";

const props = defineProps<{
  items: User[],
  command: (value: any) => any
}>()

defineExpose({
  onKeyDown
})

const selectedIndex = ref(0)
watch(() => props.items, () => {
  selectedIndex.value = 0
})

function onKeyDown(event: KeyboardEvent) {
  switch (event.key) {
    case 'ArrowUp':
      selectedIndex.value = ((selectedIndex.value + props.items.length) - 1) % props.items.length
      return true
    case 'ArrowDown':
      selectedIndex.value = (selectedIndex.value + 1) % props.items.length
      return true
    case 'Enter':
      selectItem(selectedIndex.value)
      return true
    default:
      return false
  }
}

function selectItem(index: number) {
  const item = props.items[index]
  if (item) {
    props.command({ id: item.id, label: item.name })
  }
}
</script>

<style>
.items {
  padding: 0.2rem;
  position: relative;
  border-radius: 0.5rem;
  background: #FFF;
  color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  font-size: 0.9rem;
}

.item {
  display: block;
  margin: 0;
  width: 100%;
  text-align: left;
  background: transparent;
  border-radius: 0.4rem;
  border: 1px solid transparent;
  padding: 0.2rem 0.4rem;
}
.item.is-selected {
  border-color: #000;
}
</style>
